import React, { useCallback, useState } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import { LogsPopup } from '../../Organisms/Popups/LogsPopup'
import settingGreen from './../../../imgs/SettingGreen.png'
import { formatDate } from '../../../Services/dateFormat'
import ListPage from '../../Organisms/ListPage/ListPage'

export const LogsList = () => {
    const [showPopup, setShowPopup] = useState(false)
    const [row, setRow] = useState(null)

    const showSettingsAction = useCallback(
        (item) => {
            setShowPopup(true)
            setRow(item.data)
        },
        [setRow, setShowPopup],
    )

    const activityLogTableColumn = [
        { field: 'flowName', filter: true },
        { field: 'requestIp', filter: true },
        {
            field: 'status',
            filter: true,
            valueGetter: (params) => {
                return !params.data.status ? 'Success' : 'Fail'
            },
        },
        { field: 'requestedUrl', filter: true },
        {
            field: 'createdAt',
            filter: true,
            sort: true,
            valueGetter: (params) => {
                return formatDate(new Date(params.data.createdAt))
            },
        },
        {
            field: 'Actions',
            cellRenderer: (params) => {
                return (
                    <img
                        src={settingGreen}
                        onClick={() => showSettingsAction(params)}
                        style={{
                            cursor: 'pointer',
                            paddingRight: '5px',
                            paddingLeft: '15px',
                        }}
                    />
                )
            },
        },
    ]

    return (
        <>
            {showPopup && <LogsPopup setLogsPopup={setShowPopup} row={row} />}
            <ListPage
                title="Logs"
                dataModelCall={() => {}}
                tableColumns={activityLogTableColumn}
                showSettingsAction={showSettingsAction}
            />
        </>
    )
}
